import React from 'react';
import { graphql } from 'gatsby';
import { ItemsListIndexPage, PageWithSubNav } from '../../components';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="code"
      title="Guidelines"
      designCode
      subnav="guidelines">
      <ItemsListIndexPage data={data} listType="visual" listLayout="3" />
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query guidelinesCodeIndexQuery($tierOne: String = "Guidelines") {
    ...indexList
  }
`;
